import TrialBanner from "components/common/footer-banners/TrialBanner";
import GlobalLayout from "components/page/GlobalLayout";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  PageTitle,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { css } from "linaria";
import React from "react";
import { Helmet } from "react-helmet";

const headerStyles = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--gradient-light);
  min-height: 400px;
`;

const tableStyles = css`
  border-collapse: collapse;
  width: 100%;
  font-size: 16px;
  th,
  td {
    border: 1px solid var(--nuetral-200);
    text-align: left;
    padding: 1rem;
  }
  td {
    font-weight: 300;
  }
  th {
    background-color: var(--nuetral-100);
  }
`;

export default function Licenses() {
  const licenses = [
    {
      state: "California",
      licenseNumber: "ACO8062",
      info:
        "Alarm Company Operator licensed and regulated by the California Bureau of Security and Investigative Services, Department of Consumer Affairs.",
    },
    {
      state: "Florida",
      licenseNumber: "EG13000838",
      info:
        "Certified Alarm System Contractor II licensed and regulated by the Florida Department of Business & Professional Regulation.",
    },
    {
      state: "Michigan",
      licenseNumber: "3601301783",
      info:
        "Security Alarm Contractor Agency licensed and regulated by the Michigan Department of Licensing and Regulatory Affairs.",
    },
    {
      state: "Texas",
      licenseNumber: "B28355001",
      info:
        "Alarm and Electronic Access endorsements licensed and regulated by the Texas Department of Public Safety, Texas Online Private Security division.",
    },
    {
      state: "Utah",
      licenseNumber: "13680816-6501",
      info:
        "Burglar Alarm Company licensed and regulated by the Utah Division of Professional Licensing.",
    },
    {
      state: "Virginia",
      licenseNumber: "11-20763",
      info:
        "Private Security Service Business licensed and regulated by the Virginia Department of Criminal Justice Services.",
    },
  ];
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Licenses | Rhombus</title>
      </Helmet>
      <header className={headerStyles}>
        <TextContainer>
          <PageTitle>Rhombus Systems, Inc. Licenses</PageTitle>
          <MainParagraph style={{ fontSize: "18px" }}>
            Last updated on January 5, 2024
          </MainParagraph>
        </TextContainer>
      </header>
      <SectionContainer>
        <SectionInner style={{ flexDirection: "column" }}>
          <TextContainer>
            <TitleMed>United States Alarm Monitoring License Number</TitleMed>
            <MainParagraph>
              Rhombus Alarm Monitoring is currently available in all U.S.
              states, with coverage expanding in the future.
            </MainParagraph>
          </TextContainer>
          <table className={tableStyles}>
            <thead>
              <tr>
                <th>State</th>
                <th>License Number</th>
                <th>License Info</th>
              </tr>
            </thead>
            <tbody>
              {licenses.map((license, index) => (
                <tr key={index}>
                  <td>{license.state}</td>
                  <td>{license.licenseNumber}</td>
                  <td>{license.info}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </SectionInner>
      </SectionContainer>
      <TrialBanner />
    </GlobalLayout>
  );
}
